
import { ComputedNode } from "@/admin/lib/templateMapper";
import { useI18n } from "vue-i18n";
import { defineComponent, inject, PropType, toRefs } from "vue";
import { controlledComputed } from "@vueuse/core";
import { AddValueFieldKey, ValueFieldsKey } from "./types";

export default defineComponent({
  props: {
    node: { type: Object as PropType<ComputedNode>, required: true },
    caseReadOnly: {
      type: Boolean
    }
  },
  setup(props) {
    const { t } = useI18n();
    const { node } = toRefs(props);

    const nodeId = node.value.values?.id?.toString() ?? "";

    const valueFields = inject(ValueFieldsKey);
    const field = controlledComputed(
      () => !!valueFields?.value[nodeId],
      () => valueFields?.value[nodeId]
    );

    const addValueField = inject(AddValueFieldKey, () => void 0);

    const values = controlledComputed(
      () => node.value.values,
      () => {
        if (!node.value.values) {
          return [];
        }

        const options = (node.value.values.options as Array<string>).map(x => ({
          value: x,
          text: x
        }));

        if (options.find(x => x.value === "")) {
          return options;
        }

        return [
          {
            value: "",
            text: t("common.noValue")
          },
          ...options
        ];
      }
    );

    const setFieldValue = (value: string) => {
      if (!field.value) {
        addValueField({
          name: nodeId,
          readOnly: false,
          values: {
            selectedOption: value,
            valueKind: "Dropdown"
          },
          valueKind: "Dropdown"
        });

        return;
      }

      if (field.value.values.valueKind !== "Dropdown") return;

      field.value.values = {
        ...field.value.values,
        selectedOption: value
      };
    };

    const selectedValue = controlledComputed(
      () => field.value?.values,
      () => {
        if (!field.value) {
          return undefined;
        }

        if (field.value.values.valueKind !== "Dropdown") return undefined;

        return field.value.values.selectedOption;
      }
    );

    const onChange = (event: Event) => {
      const selectElement = event.target as HTMLSelectElement;

      setFieldValue(selectElement.value);
    };

    return {
      values,
      field,
      selectedValue,
      onChange
    };
  }
});
