import { ContractCaseSigningStatus } from "./caseApi";
import { ApiResponse, apiClient } from "./client";

export const getCaseSigningStatus =
  (id: number, refresh: boolean) =>
  async (): Promise<ApiResponse<ContractCaseSigningStatusModel>> => {
    const response = await apiClient.get(
      `/cases/${id}/signing-status?refresh=${refresh.toString()}`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as ContractCaseSigningStatusModel };
    }
  };

export const getCaseSignatories =
  (id: number) => async (): Promise<ApiResponse<ContractCaseSignatory[]>> => {
    const response = await apiClient.get(`/cases/${id}/signatories`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as ContractCaseSignatory[] };
    }
  };

export const startCaseSigning =
  (dto: StartCaseSigningDto) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(
      `/cases/${dto.caseId}/start-signing`,
      {
        data: dto,
        nonLoggedErrorCodes: [400]
      }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: null
      };
    }
  };

export const updateSignatory =
  (dto: UpdateSignatoryDto) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(
      `/cases/${dto.caseId}/update-signatory`,
      {
        data: dto,
        nonLoggedErrorCodes: [400]
      }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: null
      };
    }
  };

export const sendReminder =
  (dto: SendReminderDto) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(
      `/cases/${dto.caseId}/send-signing-reminder`,
      {
        data: dto,
        nonLoggedErrorCodes: [400]
      }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: null
      };
    }
  };

export const sendReminders =
  (id: number) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(
      `/cases/${id}/send-signing-reminders`,
      {
        nonLoggedErrorCodes: [400]
      }
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: null
      };
    }
  };

export const cancelCaseSigning =
  (id: number) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(`/cases/${id}/cancel-signing`, {
      nonLoggedErrorCodes: [400]
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: null
      };
    }
  };

export const getSignedContractCasePdfLink =
  (id: number) => async (): Promise<ApiResponse<string>> => {
    const response = await apiClient.get(`/cases/${id}/signed-pdf-link`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data as string
      };
    }
  };

export type StartCaseSigningDto = {
  caseId: number;
  signatories: ContractCaseSignatory[];
  signingMessage: string | null;
};

export type UpdateSignatoryDto = {
  caseId: number;
  signatoryEmail: string;
  updatedSignatory: ContractCaseSignatory;
};

export type SendReminderDto = {
  caseId: number;
  signatoryEmail: string;
};

export type SetCaseSignatoriesDto = {
  caseId: number;
  signatories: ContractCaseSignatory[];
};

export type ContractCaseSignatory = {
  email: string;
  firstName: string;
  lastName: string;
  method: ContractCaseSigningMethod;
  phoneNumber: string;
  signingOrder: number;
  status: ContractCaseSignatoryStatus;
};

export type ContractCaseSigningMethod = "BankID" | "SMS";

export type ContractCaseSigningStatusModel = {
  signingStatus: ContractCaseSigningStatus;
  signingInitiatedDate: Date;
  signingInitiatedBy: string;
  signatories: ContractCaseSignatory[];
  updatedDate: Date;
  signedDate: Date;
  documentName: string;
  referenceId: string;
};

export enum ContractCaseSignatoryStatus {
  NONE = "None",
  SENT = "Sent",
  BOUNCED = "Bounced",
  REJECTED = "Rejected",
  SIGNED = "Signed"
}
