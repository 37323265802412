import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-3xs leading-4 flex-shrink-0 text-gray-800 whitespace-nowrap"
}
const _hoisted_2 = { class: "text-gray-500 text-2xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-col w-full h-full bg-control-gray-tint px-1.5 overflow-hidden", { 'justify-center': !_ctx.hasLabel }])
  }, [
    (_ctx.hasLabel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.labelName), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.placeholder), 1)
  ], 2))
}