
import { EntityField, ValueField } from "@/api/caseApi";
import { computed, defineComponent, inject, PropType, toRefs } from "vue";
import {
  AddEntityFieldKey,
  AddValueFieldKey,
  EntityFieldsKey,
  ExclusiveEntityGroupsKey
} from "./types";

export default defineComponent({
  props: {
    valueField: {
      type: Object as PropType<ValueField>,
      required: false
    },
    entityField: {
      type: Object as PropType<EntityField>,
      required: false
    },
    nodeId: {
      type: String,
      required: true
    },
    valueFieldName: {
      type: String,
      required: true
    },
    entityNameId: {
      type: Number,
      required: false
    },
    exclusive: {
      type: Boolean,
      default: false
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { entityNameId, nodeId, valueField, entityField } = toRefs(props);

    const exclusiveEntityGroups = inject(ExclusiveEntityGroupsKey);
    const entityFields = inject(EntityFieldsKey);
    const addEntityField = inject(AddEntityFieldKey, () => void 0);
    const addValueField = inject(AddValueFieldKey, () => void 0);

    const onChange = (event: Event) => {
      const inputElement = event.target as HTMLInputElement;
      const checked = inputElement.checked;

      if (entityNameId?.value) {
        const field = entityField?.value || {
          entityNameId: entityNameId.value,
          readOnly: false,
          valueKind: "Checkbox",
          values: { selectedOptions: [], valueKind: "Checkbox" }
        };

        if (field.values.valueKind !== "Checkbox") return;

        field.values.selectedOptions = checked ? ["checked"] : [];

        if (checked) {
          const nameId = entityNameId.value;

          exclusiveEntityGroups?.value
            ?.filter(g => g.includes(nameId))
            .flatMap(g => g.filter(n => n !== nameId))
            .map(f => entityFields?.value[f])
            .filter(f => f)
            .forEach(ef => {
              if (ef?.values.valueKind !== "Checkbox") return;
              ef.values.selectedOptions = [];
            });
        }

        if (!entityField?.value) {
          addEntityField(field);
        }
      } else {
        const field = valueField?.value || {
          name: props.valueFieldName,
          readOnly: false,
          valueKind: "Checkbox",
          values: { selectedOptions: [], valueKind: "Checkbox" }
        };

        if (field.values.valueKind !== "Checkbox") return;

        if (checked && props.exclusive) {
          field.values.selectedOptions = [nodeId.value];
        } else {
          field.values.selectedOptions = checked
            ? Array.from(
                new Set([...field.values.selectedOptions, nodeId.value])
              )
            : field.values.selectedOptions.filter(n => n !== nodeId.value);
        }

        if (!valueField?.value) {
          addValueField(field);
        }
      }
    };

    const isChecked = computed(() => {
      if (entityNameId?.value) {
        if (
          !entityField?.value ||
          entityField.value.values.valueKind !== "Checkbox"
        ) {
          return false;
        }

        return entityField.value.values.selectedOptions.length > 0;
      } else {
        if (
          !valueField?.value ||
          valueField.value.values.valueKind !== "Checkbox"
        ) {
          return false;
        }

        return valueField.value.values.selectedOptions.includes(nodeId.value);
      }
    });

    return {
      onChange,
      isChecked
    };
  }
});
