
import { defineComponent } from "vue";

export default defineComponent({
  name: "Input",
  props: {
    labelName: { type: String, required: true },
    hasLabel: { type: Boolean, required: true },
    placeholder: { type: String, required: true }
  }
});
