import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20af8ff0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pb-[1px]" }
const _hoisted_2 = ["data-value"]
const _hoisted_3 = ["disabled", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuillEditor = _resolveComponent("QuillEditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.richtext)
      ? (_openBlock(), _createBlock(_component_QuillEditor, {
          key: 0,
          theme: "snow",
          onTextChange: _ctx.onQuillChangeDebounced,
          onReady: _ctx.quillReady,
          "content-type": "html",
          toolbar: [
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }]
        ]
        }, null, 8, ["onTextChange", "onReady"]))
      : _createCommentVNode("", true),
    (!_ctx.richtext)
      ? (_openBlock(), _createElementBlock("label", {
          key: 1,
          class: _normalizeClass(["input-sizer box-border inline-grid align-top items-stretch relative border border-solid border-gray-200 p-2 w-full mb-[3px] pt-[5px] outline-none focus-within:ring-1 focus-within:ring-fa-blue after:invisible after:whitespace-pre-wrap after:outline-none after:box-border after:w-full after:antialiased after:m-0 after:resize-none after:appearance-none after:border-none", [
          !_ctx.field.readOnly && !_ctx.caseReadOnly && 'bg-control-gray-tint',
          _ctx.field.readOnly || (_ctx.caseReadOnly && 'bg-fa-turquoise bg-opacity-20')
        ]]),
          "data-value": _ctx.field.values.text
        }, [
          _withDirectives(_createElementVNode("textarea", {
            disabled: _ctx.field.readOnly || _ctx.caseReadOnly,
            class: "outline-none box-border w-full antialiased m-0 p-0 overflow-hidden resize-none appearance-none border-none",
            oninput: "this.parentNode.dataset.value = this.value",
            rows: "1",
            placeholder: _ctx.t('editContractPage.customTextPlaceholder'),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.field.values.text) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInputChangeDebounced($event)))
          }, null, 40, _hoisted_3), [
            [_vModelText, _ctx.field.values.text]
          ])
        ], 10, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}