
import { useI18n } from "vue-i18n";
import { ComputedNode } from "@/admin/lib/templateMapper";
import { Field } from "@/api/caseApi";
import { computed, defineComponent, inject, PropType, ref } from "vue";
import { controlledComputed } from "@vueuse/core";
import debounce from "lodash/debounce";
import { AddValueFieldKey, ValueFieldsKey } from "./types";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import TurndownService from "turndown";
import { marked } from "marked";
import sanitizeHtml from "sanitize-html";
import "quill-paste-smart";

interface MyQuill {
  pasteHTML: (html: string, source: string) => void;
  getHTML: () => string;
  root: HTMLElement;
}

export default defineComponent({
  components: {
    QuillEditor
  },
  props: {
    node: { type: Object as PropType<ComputedNode>, required: true },
    labelFor: { type: String, required: false },
    caseReadOnly: {
      type: Boolean
    }
  },
  setup(props) {
    const { t } = useI18n();
    const nodeId = props.node.values?.id?.toString() ?? "";
    const richtext = ref((props.node.values?.richtext as boolean) || false);
    const quillText = ref("");
    const turndownService = new TurndownService({ headingStyle: "atx" });
    let quillEditor: MyQuill;
    let isPaste = false;
    turndownService.addRule("underline", {
      filter: ["u"],
      replacement: content => `<u>${content}</u>`
    });
    turndownService.addRule("linebreak", {
      filter: ["br"],
      replacement: () => `<br/>`
    });
    turndownService.addRule("html", {
      filter: ["img", "script", "object"],
      replacement: () => ``
    });

    const valueFields = inject(ValueFieldsKey);
    const valueField = controlledComputed(
      () => !!valueFields?.value[nodeId],
      () => valueFields?.value[nodeId]
    );

    const quillReady = (quill: MyQuill) => {
      quillEditor = quill;
      quill.root.addEventListener("paste", () => (isPaste = true));

      if (richtext.value) {
        const markdown = (field.value.values as { text: string }).text || "";

        const html = marked(markdown, {
          headerIds: false,
          sanitizer: sanitize
        });

        quillEditor.pasteHTML(html, "api");
      }
    };
    const addValueField = inject(AddValueFieldKey, () => void 0);

    const field = computed<Field>(
      () =>
        valueField.value ?? {
          readOnly: false,
          valueKind: "CustomText",
          values: {
            text: "",
            valueKind: "CustomText",
            richtext: richtext.value
          }
        }
    );

    const sanitize = (html: string) => {
      return sanitizeHtml(html, {
        allowedTags: [
          "h1",
          "h2",
          "h3",
          "p",
          "strong",
          "em",
          "u",
          "pre",
          "br",
          "ol",
          "ul",
          "li"
        ]
      });
    };

    const onQuillChanged = () => {
      let text = turndownService.turndown(sanitize(quillEditor.root.innerHTML));

      if (isPaste) {
        isPaste = false;
        const html = marked(text, {
          headerIds: false,
          sanitizer: sanitize
        });

        quillEditor.pasteHTML(html, "api");
        text = turndownService.turndown(sanitize(quillEditor.root.innerHTML));
      }

      if (!valueField.value) {
        addValueField({
          name: nodeId,
          readOnly: false,
          values: {
            text,
            richtext: richtext.value,
            valueKind: "CustomText"
          },
          valueKind: "CustomText"
        });
      } else if (valueField.value.values.valueKind === "CustomText") {
        valueField.value.values.text = text;
      }
    };

    const onInputChanged = (event: Event) => {
      const inputElement = event.target as HTMLInputElement;

      if (!valueField.value) {
        addValueField({
          name: nodeId,
          readOnly: false,
          values: {
            text: inputElement.value,
            richtext: richtext.value,
            valueKind: "CustomText"
          },
          valueKind: "CustomText"
        });
      } else if (valueField.value.values.valueKind === "CustomText") {
        valueField.value.values.text = inputElement.value;
      }
    };

    const onQuillChangeDebounced = debounce(onQuillChanged, 250);
    const onInputChangeDebounced = debounce(onInputChanged, 250);

    return {
      field,
      quillText,
      richtext,
      quillReady,
      onQuillChangeDebounced,
      onInputChangeDebounced,
      t
    };
  }
});
